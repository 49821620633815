/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from "react";
import { matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  List,
  makeStyles,
  Avatar,
  Typography,
  Button,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import NavItem from "src/layouts/DashboardLayout/NavBar/NavItem";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { UserContext } from "src/context/User";
import {
  FaWallet,
  FaSignOutAlt,
  FaUserEdit,
  FaSignInAlt,
} from "react-icons/fa";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";

const sections = [
  {
    title: "Account setting",
    href: "/account-setting",
    icon: FaUserEdit,
  },

  {
    title: "Logout",
    href: "/dashboard",
    icon: FaSignOutAlt,
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: "100%",
    maxWidth: "315px",
    top: "30px",
    right: "0px",
    border: "1px solid rgba(0, 0, 0, 0.5)",
    padding: "12px 15px 32px 15px",
    overflow: "unset",
    position: "absolute",
    background: "#2A2A2A",
    borderRadius: "10px",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      maxWidth: "300px",
      right: 0,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "290px",
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 272,
      right: "-17",
    },
  },
  balanceDiv: {
    display: "block",
    justifyContent: "space-between",
    padding: "34px 20px",
    background: "#3E3E3E",
    borderRadius: "10px",
    alignItems: "center",
    display: "grid",
    gap: "7px",
    [theme.breakpoints.down("md")]: {
      padding: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "12px",
    },
  },
  listMenu: {
    display: "grid",
    gap: "20px",
    padding: "0 34px",
    [theme.breakpoints.down("md")]: {
      padding: "0, 20px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 16px",
    },
  },
  balanceSecondDiv: {
    display: "flex",
    gap: "20px",
    fontSize: "20px",
    alignItems: "center",
  },
  balanceSecondDivLength: {
    display: "grid",
    gap: "15px",
    fontSize: "20px",
  },
  menuListText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
    },
  },
  balanceText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "38px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    wordBreak: "break-all",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  logoutButton: {
    background: "linear-gradient(180.99deg, #2FF3FF -25%, #1E92AA 141.48%)",
    borderRadius: "5px",
    padding: "12px 0",
    textAlign: "center",
    border: "none",
    cursor: "pointer",
  },
  loader: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    border: "3px solid #fff",
    borderTopColor: "transparent",
    animation: "$spin 1s infinite linear",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const [Eyebutton, setEyebutton] = useState(true);
  const [rightBar, setRightBar] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const user = useContext(UserContext);
  useEffect(() => {
    user.getViewMyProfile();
    user.exchangeWallet();
  }, []);
  const confirmationHandler = () => {
    toast.success("Logout successfully.");
    window.localStorage.removeItem("token");
    window.localStorage.clear();
    history.push("/login");
  };
  const exchangeBalance = user?.exchangeBalance?.allExchangeTotal;

  const profilePic = user?.profile?.profilePic
    ? user?.profile?.profilePic
    : null;

  const content = (
    <>
      <Box style={{ display: "grid", gap: "31px" }}>
        <Box className={classes.balanceDiv}>
          {/* here we want to show user's eamil */}
          <Typography className={classes.email}>
            {user?.profile?.email && user?.profile?.email}
          </Typography>
          <Typography className={classes.menuListText}>
            Total assets value(USDT):
          </Typography>
          {exchangeBalance == undefined ? (
            <div className={classes.loader}></div>
          ) : (
            <Box className={classes.balanceSecondDiv}>
              {exchangeBalance && (
                <Typography className={classes.balanceText}>
                  {/* {exchangeBalance} */}
                  <span>
                    {Eyebutton
                      ? exchangeBalance && exchangeBalance.toFixed(4)
                      : "*".repeat(
                          exchangeBalance.toFixed(4).toString()?.length
                        )}
                  </span>
                </Typography>
              )}
              {Eyebutton ? (
                <img
                  src="/images/Eye.png"
                  style={{
                    width: "20px",
                  }}
                  onClick={() => setEyebutton(!Eyebutton)}
                />
              ) : (
                <img
                  src="/images/Hide.png"
                  style={{
                    width: "20px",
                  }}
                  onClick={() => setEyebutton(!Eyebutton)}
                />
              )}

              {exchangeBalance && <img src="/images/Credit card.svg" />}
            </Box>
          )}
        </Box>
        <Box className={classes.listMenu}>
          <Box>
            {" "}
            <Typography
              onClick={() => history.push("/account-setting")}
              className={classes.menuListText}
            >
              Account Settings
            </Typography>
          </Box>
          {/* <Box>
            {" "}
            <Typography className={classes.menuListText}>
              Security Center
            </Typography>
          </Box>
          <Box>
            {" "}
            <Typography
              onClick={() => history.push("/api-binding")}
              className={classes.menuListText}
            >
              API Binding
            </Typography>
          </Box> */}
          <Box onClick={() => history.push("/olympus-support")}>
            {" "}
            <Typography className={classes.menuListText}>
              Olympus Support
            </Typography>
          </Box>
          {/* <Box>
            {" "}
            <Typography
              onClick={() => history.push("/pricing")}
              className={classes.menuListText}
            >
              Activate Account
            </Typography>
          </Box> */}
          {/* <Box onClick={() => setOpen(true)} className={classes.logoutButton}>
            <Typography className={classes.menuListText}>Logout</Typography>
          </Box> */}
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <img
        style={{
          width: "35px",
          height: "35px",
          borderRadius: "100%",
          cursor: "pointer",
        }}
        src={profilePic ? profilePic : "/images/profile.svg"}
        onClick={() => {
          setRightBar(!rightBar);
        }}
      />

      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        // PaperComponent={Paper}
        // PaperProps={{
        //   style: {
        //     position: 'fixed',
        //     top: 20,
        //     right: 20,
        //     maxWidth: 'calc(100% - 40px)',
        //   },
        // }}
        open={rightBar}
        onClose={() => {
          setRightBar(false);
        }}
      >
        {content}
      </Dialog>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Are you sure you want to logout ?"}
          confirmationHandler={confirmationHandler}
        />
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
