import React, { useContext, useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, ListItem, makeStyles } from "@material-ui/core";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "src/context/User";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    height: "46px",
    // justifyContent: 'center',
    "& .MuiButton-root.Mui-disabled": {
      color: "#2FF3FF",
    },
  },
  button: {
    // color: "#000000",
    color: theme.palette.text.primary,
    padding: "10px 24px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    // color: theme.palette.text.primary,
    // padding: "17px 8px",

    color: "#2FF3FF",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    "& .MuiButton-label": {
      padding: "10px",
    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#2FF3FF !important",
        background: "rgba(255, 255, 255, 0.1)",
        // padding: "10px",
        borderRadius: "9px",
        height: "21px",
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
          // color: `${theme.palette.text.primary} !important`,
        },
        "& $icon": {
          color: "#2FF3FF !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: `#fff !important`,
    width: "26px",
  },
  title: {
    marginRight: "auto",
  },
  active: {
    "& .MuiButton-label": {
      color: "#FFFFFF",
      height: "21px",
      background: "rgba(255, 255, 255, 0.1)",
      padding: "10px",
      paddingRight: "83px",
      borderRadius: "9px",
      fontWeight: theme.typography.fontWeightRegular,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,

  
      },
      "& $icon": {
        color: "#2FF3FF !important",
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },
  logOutButton: {
    background: "linear-gradient(94.88deg, #15D1D3 0%, #008BD9 81.78%)",
    borderRadius: "5px",
    height: "40px",
    padding: "10px 47px",
    marginLeft: "30px",
    marginTop:'36px',
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "14px",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon,
  activeIcon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [logOut, setLogOut] = useState(false);
  const user = useContext(UserContext);
const ButtonTrue = user.isTrue


  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };


  const confirmationHandler = () => {
    user.logOutDialogClose();
    toast.success("Logout successfully.");
    window.localStorage.removeItem("token");
    window.localStorage.clear();
    history.push("/login");
  };
  return (
    <>
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        {title == "Logout" ? (
          <Button
            activeClassName={classes.logOutButton}
            className={classes.logOutButton}
            // disabled={ButtonTrue}
            // style={style}
            onClick={() => setLogOut(true)}
          >
            <span className={classes.title}>{"Logout"}</span>
            {Info && <Info />}
          </Button>
        ) : (
          <Button
            // disabled={
            //   icon == "/images/dashboardIcon/copier.svg" 
            //   // ||
            //   // icon == "/images/dashboardIcon/marketplace.svg" ||
            //   // icon == "/images/dashboardIcon/securityCenter.svg" 
            //   // || icon == "/images/dashboardIcon/strategyBot.svg" 
            //   // || title == "API Binding"
            // }
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            exact
            style={style}
            to={{ pathname: href, state: { tabName: "assetDetails" } }}
          >
            {location.pathname == href ? (
              <img className={classes.icon} src={icon} size="20" alt="-"/>
            ) : (
              <img className={classes.icon} src={activeIcon} size="20" alt="-"/>
            )}
            {/* {Icon && <Icon className={classes.icon} size="20" />} */}
            <span className={classes.title}>{title}</span>
            {/* {Info && <Info />} */}
          </Button>
        )}
      </ListItem>
      {logOut && (
        <ConfirmationDialog
          open={logOut}
          handleClose={() => setLogOut(false)}
          title={"Logout"}
          desc={"Are you sure you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
