import React, { createContext, useState, useEffect } from "react";

import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Box, Button, Dialog, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "fit-content",
    borderRadius: "10px",
    background: "transparent",
    boxShadow: " 0px 1px 3px 0px rgba(0, 0, 0, 0.0)",
    overflow: "hidden",
    "& .MuiDialogContent-root": {
      flex: "none !important",
    },
    "& .MuiDialogActions-root": {
      marginRight: "0px !important",
    },
  },
  dialogDiv: {
    float: "right",
    cursor: "pointer",
    display: "flex",
    justifyContent: "end",
    marginRight: "-11px",
  },
  dialogBnnerDiv: {
    padding: "0px",
    overflow: "hidden",
  },
  dialogBnnerImg: {
    maxWidth: "444px",
    borderRadius: "10px !important",
    overflow: "hidden",
  },
  rootDialogBox: {
    backgroundImage: "url('/images/alertBackground.svg') !important",
    backgroundSize: "cover !important",
    borderRadius: "26px",
    backgroundRepeat: "no-repeat",
    background: "transparent",
    backgroundColor: "#161616",
  },
  subMainHead: {
    background: "linear-gradient(142deg, #FFF 0%, #008BD9 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "normal",
  },
  subMain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#fff",
  },
  subMainSub: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#fff",
    textAlign: "center",
  },
  DisableButton: {
    borderRadius: "7.55px",
    background: "linear-gradient(144deg, #15D1D3 0%, #008BD9 100%)",
    maxWidth: "202px",
    height: "45px",
    color: "#fff",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    lineHeight: "normal",
    textTransform: "capitalize",
    boxShadow:
      "2.5167958736419678px 2.5167958736419678px 2.5167958736419678px 0px rgba(255, 255, 255, 0.10) inset, -2.5167958736419678px -2.5167958736419678px 2.5167958736419678px 0px rgba(0, 0, 0, 0.10) inset",
  },
}));
export const UserContext = createContext();

export default function AuthProvider(props) {
  const history = useHistory();
  const classes = useStyles();
  const [profile, setProfile] = useState({});
  const [banner, setBanner] = useState(null);
  const [exchangeBalance, setExchangeBalance] = useState({});
  const [coinList, setCointList] = useState([]);
  const [open, SetOpen] = useState(false);
  const [autoRenewOpen, setAutoRenewOpen] = useState(false);
  const [depositAsset, setDepositAsset] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [purchasePlan, setPurchasePlan] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState(false);
  const [planExpiryDate, setPlanExpiryDate] = useState({
    text: "",
    open: false,
  });
  const [userPlan, setUserPlan] = useState({
    planExpireStatus: false,
    planSubscription: true,
  });
  const pathname =
    window.location.pathname == "/register/:inviteCode?" ||
    window.location.pathname == "/privacy-policy" ||
    window.location.pathname == "/signup-otp" ||
    window.location.pathname == "/terms&condition" ||
    window.location.pathname == "/login" ||
    window.location.pathname == "/risk-notice" ||
    window.location.pathname == "/verify-otp" ||
    window.location.pathname == "/user-service" ||
    window.location.pathname == "/reset-password" ||
    window.location.pathname == "/disclaimer" ||
    window.location.pathname == "/forget-password"
      ? false
      : true; // prevent the dialogbox open for above pathname
  const logOutDialogClose = () => {
    SetOpen(false);
    setAutoRenewOpen(false);
    setUserPlan({
      planExpireStatus: false,
      planSubscription: true,
    });
    setPlanExpiryDate({
      text: "",
      open: false,
    });
    setUpgradePlan(false);
    setDepositAsset(false);
    setTimeout(() => {
      SetOpen(false);
      setAutoRenewOpen(false);
      setUserPlan({
        planExpireStatus: false,
        planSubscription: true,
      });
      setPlanExpiryDate({
        text: "",
        open: false,
      });
      setDepositAsset(false);
      setUpgradePlan(false);
    }, 5000);
    setTimeout(() => {
      SetOpen(false);
      setAutoRenewOpen(false);
      setUserPlan({
        planExpireStatus: false,
        planSubscription: true,
      });
      setPlanExpiryDate({
        text: "",
        open: false,
      });
      setDepositAsset(false);
      setUpgradePlan(false);
    }, 10000);

  };
  const handleClose = () => {
    SetOpen(false);
    setTimeout(() => {
      getMyPlanDetails();
    }, 5000);
  };
  const handleCloseAutoRenewOpen = () => {
    setAutoRenewOpen(false);
  };
  const handleOpenAutoRenewOpen = () => {
    setAutoRenewOpen(true);
  };
  const handleClosePlan = () => {
    setUserPlan({
      planExpireStatus: false,
      planSubscription: true,
    });
    setTimeout(() => {
      if (planExpiryDate.text != "" && planExpiryDate.text != "Expired") {
        setPlanExpiryDate({
          text: planExpiryDate.text,
          open: true,
        });
      } else if (exchangeBalance.allExchangeTotal < 10) {
        setAutoRenewOpen(true);
      }
    }, 10000);
  };
  const handleCloseDeposite = () => {
    setDepositAsset(false);
  };
  const handleCloseExpiry = () => {
    setPlanExpiryDate({
      text: "",
      open: false,
    });
    setTimeout(() => {
      if (exchangeBalance.allExchangeTotal < 10) {
        setAutoRenewOpen(true);
      }
    }, 10000);
  };
  const handleCloseNewPlan = () => {
    setPurchasePlan(false);
  };
  const handleOpenNewPlan = () => {
    setPurchasePlan(true);
  };
  const handleCloseUpgradePlan = () => {
    setUpgradePlan(false);
  };
  const handleOpenUpgradePlan = () => {
    setUpgradePlan(true);
  };
  const getViewMyProfile = async (values) => {
    const token = localStorage.getItem("token");

    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewMyProfile,
        headers: { token: token },
      });
      console.log(res, "adminProfile");
      if (res.data.statusCode === 200) {
        setProfile(res.data.result);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (
        error.response.data.responseMessage == "User not found" ||
        error.response.data.responseMessage ==
          "User has been blocked by admin" ||
        error.response.data.responseMessage == "jwt malformed" ||
        error.response.data.responseMessage == "jwt expired"
      ) {
        window.localStorage.clear();
        history.push("/login");
      }
    }
  };
  const getMyPlanDetails = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewMyProfile,
        headers: { token: token },
      });
      if (res.data.statusCode === 200) {
        setPlanExpiryDate({
          text: res.data.result.planExpiryDate,
          Dialog: false,
        });
        if (res.data.result.depositAsset === true) {
          setDepositAsset(res.data.result.depositAsset);
        } else if (
          res.data.result.planSubscription === false &&
          res.data.result.planExpireStatus === true
        ) {
          setUserPlan({
            planExpireStatus: res.data.result.planExpireStatus,
            planSubscription: res.data.result.planSubscription,
          });
        } else if (
          res.data.result.planExpiryDate != "" &&
          res.data.result.planExpiryDate != "Expired"
        ) {
          setPlanExpiryDate({
            text: res.data.result.planExpiryDate,
            Dialog: true,
          });
        } else if (
          res.data.result.planSubscription === false &&
          res.data.result.planExpireStatus === false
        ) {
          handleOpenNewPlan();
        } else if (exchangeBalance < 10 && exchangeBalance > -10) {
          setAutoRenewOpen(true);
        }
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {}
  };
  const exchangeWallet = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.exchangeWallet,
        headers: { token: token },
      });
      if (res.data.statusCode === 200) {
        setExchangeBalance(res.data.result);
      } else {
        // toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };
  const advertismentBanner = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getAdvertismentForUser,
        headers: {
          token: token,
        },
      });

      if (res.data.statusCode === 200) {
        if (res?.data?.result[0] != undefined) {
          setTimeout(() => {
            setBanner(res.data.result[0].img);
            SetOpen(true);
          }, 5000);
        } else {
          setTimeout(() => {
            getMyPlanDetails();
          }, 5000);
        }
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (
        error?.response?.data?.responseMessage == "User not found" ||
        error?.response?.data?.responseMessage == "jwt malformed"
      ) {
        window.localStorage.clear();
        history.push("/login");
      }
      if (error.response.data.statusCode == 404) {
      }
    }
  };
  const useSetStateAfterDelay = (delay) => {
    setIsTrue(true);
    setTimeout(() => {
      setIsTrue(false);
    }, 15000);
  };
  // coin list websocket
  const [search, setSearch] = useState("");
  const [type, setType] = useState("HOT");
  const [marketType, setMarketType] = useState("SPOT");
  // const [webData, setwebData] = useState(true);
  const handleCoinFilter = (search, marketType, type) => {
    setSearch(search);
    setMarketType(marketType);
    setType(type);
  };

  // const ws = new WebSocket("ws://172.16.6.204/");

  useEffect(() => {
    const ws = new WebSocket("wss://nodepune-royalq.mobiloitte.io");
    const apiCall = {
      type: "getTickers",
      userId: profile._id,
      query: {
        exchange: "Binance",
        market: "USDT",
        type: type,
        marketType: marketType,
        search: search?.toUpperCase(),
        limit: 150,
        page: 1,
      },
    };
    ws.onopen = (event) => {
      console.log("WebSocket connection established.");
      ws.send(JSON.stringify(apiCall));
    };
    ws.onmessage = function (event) {
      try {
        if (event.data !== "[object Promise]") {
          const json = JSON.parse(event.data);
          setCointList(json);
          // if( json?.marketType == marketType ||
          //   json?.type == type){
          //     setwebData(false);
          // }else{
          //   setwebData(true);
          // }
        }
      } catch (err) {
        console.log("Error parsing JSON:", err);
      }
    };
    ws.onclose = () => {
      console.log("WebSocket connection closed. Retrying...");
    };
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      // Optionally, you can add additional error handling logic here
    };
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, [search, type, marketType]);

  let data = {
    profile,
    exchangeBalance,
    coinList,
    isTrue,
    // webData,
    getViewMyProfile: () => getViewMyProfile(),
    exchangeWallet: () => exchangeWallet(),
    logOutDialogClose: () => logOutDialogClose(),
    advertismentBanner: () => advertismentBanner(),
    useSetStateAfterDelay: () => useSetStateAfterDelay(),
    handleOpenNewPlan: () => handleOpenNewPlan(),
    handleOpenUpgradePlan: () => handleOpenUpgradePlan(),
    handleCloseNewPlan: () => handleCloseNewPlan(),
    handleOpenAutoRenewOpen: () => handleOpenAutoRenewOpen(),
    handleCloseAutoRenewOpen: () => handleCloseAutoRenewOpen(),
    handleCoinFilter: (search, marketType, type) =>
      handleCoinFilter(search, marketType, type),
  };

  return (
    <UserContext.Provider value={data}>
      {props.children}

      {open && pathname && (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.root,
            },
            style: {
              maxWidth: 480,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              background: "#2A2A2A",
              justifyContent: "center",
              padding: "6px",
            }}
          >
            <div className={classes.dialogDiv} onClick={handleClose}>
              <img src="/images/tagcross.svg" alt="-" />
            </div>
            <div className={classes.dialogBnnerDiv} style={{}}>
              <img src={banner} className={classes.dialogBnnerImg} alt="-" />
            </div>
          </Box>
        </Dialog>
      )}
      {autoRenewOpen && exchangeBalance.allExchangeTotal < 10 && pathname && (
        <Dialog
          open={autoRenewOpen}
          onClose={handleCloseAutoRenewOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 315,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Insufficient Fuel Fee!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMain}
              >
                Your fuel fee is below $10.
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMain}
              >
                To ensure uninterrupted <br />
                access to Olympus Services, <br />
                please deposit <br />
                the required fuel fee.
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  history.push({
                    pathname: "/asset",
                    state: { tabName: "deposit" },
                  });
                  setAutoRenewOpen(false);
                }}
                fullWidth
              >
                Deposit Now
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {userPlan.planExpireStatus && !userPlan.planSubscription && pathname && (
        <Dialog
          open={userPlan.planExpireStatus && !userPlan.planSubscription}
          onClose={handleClosePlan}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 315,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Plan Expired: Renew Now!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Your plan has been expired!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                To continue using Olympus Services without any disruption,
                please renew your plan immediately.
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  handleClosePlan();
                  history.push({
                    pathname: "/pricing",
                  });
                }}
                fullWidth
              >
                Renew Now
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {depositAsset && (
        <Dialog
          open={depositAsset}
          onClose={handleCloseDeposite}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 385,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Supercharge your journey!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Deposit now to unlock a world of
                <br /> possibilities.
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Empower yourself with new
                <br /> features and seize exciting
                <br />
                opportunities using your funds.
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Take control of your financial
                <br /> future - deposit today!
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  handleCloseDeposite();
                  history.push({
                    pathname: "/asset",
                    state: { tabName: "deposit" },
                  });
                }}
                fullWidth
              >
                Deposit Now
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {planExpiryDate.open && (
        <Dialog
          open={planExpiryDate.open}
          onClose={handleCloseExpiry}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 385,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Plan Expired: Renew Now!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Your current plan will expire in <br /> [{planExpiryDate.text}]
              </Typography>

              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Ensure that your Auto Renew <br />
                feature is enabled or consider upgrading your plan now.
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                consider upgrading your plan now.
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  handleCloseExpiry();
                  history.push({
                    pathname: "/pricing",
                  });
                }}
                fullWidth
              >
                Renew Now
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {purchasePlan && (
        <Dialog
          open={purchasePlan}
          onClose={handleCloseNewPlan}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 385,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Maximize Olympus: <br />
                Upgrade Now!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Unleash the full potential with our exclusive spot and future
                plan.
              </Typography>

              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Enjoy premium features, enhanced performance, and a seamless
                experience.
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Buy now and elevate your journey!
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  handleCloseNewPlan();
                  history.push({
                    pathname: "/pricing",
                  });
                }}
                fullWidth
              >
                View Plans
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {upgradePlan && (
        <Dialog
          open={upgradePlan}
          onClose={handleCloseUpgradePlan}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 360,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Upgrade to the Future Plan!
              </Typography>
              <Typography
                style={{ fontSize: "18px", marginTop: "20px" }}
                className={classes.subMainSub}
              >
                Unlock better opportunities and seize the possibilities that lie
                ahead.
              </Typography>

              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Upgrade now and embrace a brighter tomorrow!
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  handleCloseUpgradePlan();
                  history.push({
                    pathname: "/pricing",
                    // state:{bySubscription: true , buttonValue:"future", spotBotDetails:"FutureBot"},
                  });
                }}
                fullWidth
              >
                View Plans
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </UserContext.Provider>
  );
}
