import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import Scroll from "react-scroll";
import {
  Drawer,
  Grid,
  List,
  ListItem,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "block",
    "@media(max-width:1278px)": {
      display: "none",
    },
  },
  root1: {
    display: "none",
    "@media(max-width:1278px)": {
      display: "block",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-around",
  },
  linkButtonsDiv: {
    display: "flex",
    gap: "14px",
  },
  //   logo: {
  //     flexGrow: 1,
  //   },

  register: {
    background: "linear-gradient(89.1deg, #00F0FF 1.02%, #1E92AA 72.36%)",
    borderRadius: "7.61905px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15.2381px",
    lineHeight: "18px",
    color: "#FFFFFF",
    cursor: "pointer",
    padding: "12px 24px",
    textTransform: "none",
  },
  linkButton: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    color: "#98B0BB",
    textTransform: "none",
    cursor: "pointer",
    "@media(max-width:1350px)": {
      fontSize: "12px",
    },
  },
  menu: {
    "& .MuiMenu-paper": {
      transform: "translate(0, 30px) !important",
    },
  },
  menu1: {
    marginTop: "8px",
    textDecoration: "none",
  },
  linkButton1: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25px",
    color: "#FFFFFF",
    textTransform: "none",
    cursor: "pointer",
    background: "transparent",
    border: "none",
  },
  spotSmartT: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "25px",
    color: "#FFFFFF",
    textTransform: "none",
    cursor: "pointer",
    background: "transparent",
    border: "none",
    margin: "5px 11px",
    "& .MuiSelect-icon": {
      color: "#fff",
    },
  },
  gridfour: {
    "& .MuiInput-underline:before ": {
      borderBottom: "none !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none !important",
    },
  },
  Login: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15.2381px",
    lineHeight: "18px",
    color: "#FFFFFF",
    cursor: "pointer",
    background: "linear-gradient(320deg, #1F7C99 0%, #15D7D7 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    textTransform: "none",
  },
  registerBox: {
    display: "flex",
    gap: "20px",
  },
  drawerRight: {
    "& .MuiDrawer-paperAnchorRight": {
      background: "#161616",
      padding: "30px 20px",
    },
  },
  toolbarlogo: {
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    width: "100%",
    // maxWidth: "154px",
  },
}));

export default function TopBar() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerContent, setOpenDrawerContent] = useState(false);
  const [logIn, setLogIn] = useState(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedsecond, setSelectedsecond] = useState("");
  const [selectedT, setSelectedT] = useState("");
  const [selectedS, setSelectedS] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChangeSecon = (event) => {
    setSelectedsecond(event.target.value);
  };
  const handleChangeT = (event) => {
    setSelectedT(event.target.value);
  };
  const handleChangeS = (event) => {
    setSelectedS(event.target.value);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const handleDrawerOpen = () => {
    setOpenDrawerContent(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawerContent(false);
  };
  const inviteCode = "";
  const signupUrl = `${"/register"}?inviteCode=${inviteCode}`;
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token !== null && token !== "undefined") {
      setLogIn(true);
      
    } else {
      setLogIn(false);
      
    }
  }, [token]);

  const supportEmailClient = () => {
    window.location.href = `mailto:${"support@olympustrade.ai"}`;
  };
  const helpEmailClient = () => {
    window.location.href = `mailto:${"info@olympustrade.ai"}`;
  };
  const redirectToTwitter = () => {
    const url = `https://twitter.com/Olympus_AiTrade`;
    window.open(url, "_blank");
  };
  const redirectToTelegram = () => {
    const url = `https://t.me/Olympustrade_announcement`;
    window.open(url, "_blank");
  };
  const redirectSupportToTelegram = () => {
    const url = `https://t.me/Olympustrade_announcement`;
    window.open(url, "_blank");
  };
  const redirectToYoutube = () => {
    const url = `https://www.youtube.com/@OlympusCryptoAIbot`;
    window.open(url, "_blank");
  };
  const redirectToMedium = () => {
    const url = `https://medium.com/@olympus_trade`;
    window.open(url, "_blank");
  };
  const redirectToInstagram = () => {
    const url = `https://www.instagram.com/olympustrade_official/`;
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{ backgroundColor: "#0E2326", height: "67px" }}
        >
          <Toolbar className={classes.toolbar}>
            <Box>
              <img className={classes.logo} src="/images/logo.svg" />
            </Box>
            <Box className={classes.linkButtonsDiv}>
              <Button className={classes.linkButton}>Home</Button>
              <Button className={classes.linkButton} onClick={handleClick}>
                Spot Smart Trade &nbsp;&nbsp;
                <img src="/images/DroplistIcon.svg" alt="" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
              >
                {" "}
                <MenuItem onClick={handleClose}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    SPOT GRID TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    FUTURE GRID TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    COIN-M FUTURE GRID TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    DCA TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    COPY TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {" "}
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    INDICATOR BASED GRID TRADING
                  </ScrollLink>
                </MenuItem>
              </Menu>
              <Button className={classes.linkButton} onClick={handleClick1}>
                Future Smart Trade &nbsp;&nbsp;
                <img src="/images/DroplistIcon.svg" alt="" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={handleClose1}
                className={classes.menu}
              >
                <MenuItem onClick={handleClose1}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose1();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    SPOT GRID TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose1}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose1();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    FUTURE GRID TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose1}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose1();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    COIN-M FUTURE GRID TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose1}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose1();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    DCA TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose1}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose1();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    COPY TRADING
                  </ScrollLink>
                </MenuItem>
                <MenuItem onClick={handleClose1}>
                  <ScrollLink
                    className=""
                    onClick={() => {
                      history.push("/");
                      handleClose1();
                    }}
                    smooth={true}
                    duration={500}
                    to="advance-feature"
                    style={{ cursor: "pointer" }}
                  >
                    INDICATOR BASED GRID TRADING
                  </ScrollLink>
                </MenuItem>
              </Menu>

              <ScrollLink
                onClick={() => {
                  history.push("/");
                  handleClose1();
                }}
                smooth={true}
                duration={500}
                to="affilate"
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                <Button className={classes.linkButton}>Affilate</Button>
              </ScrollLink>

              <ScrollLink
                onClick={() => {
                  history.push("/");
                  handleClose1();
                }}
                smooth={true}
                duration={500}
                to="pricing"
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                <Button className={classes.linkButton}>Pricing</Button>
              </ScrollLink>

              <Button className={classes.linkButton} onClick={handleClick3}>
                Support &nbsp;&nbsp;
                <img src="/images/DroplistIcon.svg" alt="" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl3}
                keepMounted
                open={Boolean(anchorEl3)}
                onClose={handleClose3}
                className={classes.menu}
              >
                <MenuItem onClick={handleClose3}>Live Chat</MenuItem>
                <MenuItem onClick={handleClose3}>
                  {" "}
                  <ScrollLink
                    onClick={() => history.push("/")}
                    smooth={true}
                    duration={500}
                    to="footer-section"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    Email
                  </ScrollLink>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose3();
                    redirectSupportToTelegram();
                  }}
                >
                  Telegram Chat
                </MenuItem>
              </Menu>
              {/* <Button className={classes.linkButton}>About</Button> */}
              <ScrollLink
                onClick={() => history.push("/")}
                smooth={true}
                duration={500}
                to="hypertrade"
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                <Button className={classes.linkButton}>About Us</Button>
              </ScrollLink>

              <Button className={classes.linkButton} onClick={handleClick2}>
                Social Media &nbsp;&nbsp;
                <img src="/images/DroplistIcon.svg" alt="" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
                className={classes.menu}
              >
                <MenuItem
                  onClick={() => {
                    redirectToTwitter();
                    handleClose2();
                  }}
                >
                  Twitter
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToYoutube();
                    handleClose2();
                  }}
                >
                  Youtube
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToMedium();
                    handleClose2();
                  }}
                >
                  Medium
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToTelegram();
                    handleClose2();
                  }}
                >
                  Telegram
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    redirectToInstagram();
                    handleClose2();
                  }}
                >
                  Instagram
                </MenuItem>
              </Menu>
            </Box>
            {logIn == true && (
              <Box className={classes.registerBox}>
                <Button
                  className={classes.register}
                  onClick={() => history.push("/dashboard")}
                >
                  Dashboard
                </Button>
              </Box>
            )}
            {logIn == false && (
              <Box className={classes.registerBox}>
                <Button
                  className={classes.register}
                  onClick={() => history.push(signupUrl)}
                >
                  Register
                </Button>
                <Button
                  className={classes.Login}
                  onClick={() => history.push("/login")}
                >
                  Login
                </Button>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </div>
      <div className={classes.root1}>
        <AppBar
          position="static"
          style={{ backgroundColor: "#0E2326", height: "67px" }}
        >
          <Toolbar className={classes.toolbarlogo}>
            <Box>
              <img className={classes.logo} src="/images/logo.svg" />
            </Box>
            <Box>
              <MenuIcon onClick={handleDrawerOpen} />
            </Box>
          </Toolbar>
          <Drawer
            anchor="right"
            open={openDrawerContent}
            onClose={handleDrawerClose}
            className={classes.drawerRight}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.gridfour}
                style={{ width: "10rem" }}
              >
                <List>
                  <img className={classes.logo} src="/images/logo.svg" />
                  <ListItem className={classes.linkButton1}>Home</ListItem>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.spotSmartT}
                    MenuProps={{ classes: { paper: classes.menu1 } }}
                  >
                    <MenuItem value="" disabled className={classes.menuItem}>
                      Spot Smart Trade
                    </MenuItem>
                    <MenuItem value="Price" className={classes.menuItem}>
                      SPOT GRID TRADING
                    </MenuItem>
                    <MenuItem value="Percentage" className={classes.menuItem}>
                      FUTURE GRID TRADING
                    </MenuItem>
                    <MenuItem value="Indicator" className={classes.menuItem}>
                      COIN-M FUTURE GRID TRADING
                    </MenuItem>
                    <MenuItem value="DCA" className={classes.menuItem}>
                      DCA TRADING
                    </MenuItem>
                    <MenuItem value="Copy" className={classes.menuItem}>
                      COPY TRADING
                    </MenuItem>
                    <MenuItem
                      value="IndicatorBased"
                      className={classes.menuItem}
                    >
                      INDICATOR BASED GRID TRADING
                    </MenuItem>
                  </Select>

                  <Select
                    value={selectedsecond}
                    onChange={handleChangeSecon}
                    displayEmpty
                    className={classes.spotSmartT}
                    MenuProps={{ classes: { paper: classes.menu1 } }}
                  >
                    <MenuItem value="" disabled className={classes.menuItem}>
                      Future Smart Trade
                    </MenuItem>
                    <MenuItem value="Price" className={classes.menuItem}>
                      SPOT GRID TRADING
                    </MenuItem>
                    <MenuItem value="Percentage" className={classes.menuItem}>
                      FUTURE GRID TRADING
                    </MenuItem>
                    <MenuItem value="Indicator" className={classes.menuItem}>
                      COIN-M FUTURE GRID TRADING
                    </MenuItem>
                    <MenuItem value="DCA" className={classes.menuItem}>
                      DCA TRADING
                    </MenuItem>
                    <MenuItem value="Copy" className={classes.menuItem}>
                      COPY TRADING
                    </MenuItem>
                    <MenuItem
                      value="IndicatorBased"
                      className={classes.menuItem}
                    >
                      INDICATOR BASED GRID TRADING
                    </MenuItem>
                  </Select>
                  <ScrollLink
                    onClick={() => history.push("/")}
                    smooth={true}
                    duration={500}
                    to="affilate"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <ListItem className={classes.linkButton1}>
                      Affilate
                    </ListItem>
                  </ScrollLink>
                  <ScrollLink
                    onClick={() => history.push("/")}
                    smooth={true}
                    duration={500}
                    to="pricing"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <ListItem className={classes.linkButton1}>Pricing</ListItem>
                  </ScrollLink>
                  <Select
                    value={selectedT}
                    onChange={handleChangeT}
                    displayEmpty
                    className={classes.spotSmartT}
                    MenuProps={{ classes: { paper: classes.menu1 } }}
                  >
                    <MenuItem value="" disabled className={classes.menuItem}>
                      Support
                    </MenuItem>
                    <MenuItem value="Live" className={classes.menuItem}>
                      Live Chat
                    </MenuItem>
                    <MenuItem value="Email" className={classes.menuItem}>
                      <ScrollLink
                        onClick={() => history.push("/")}
                        smooth={true}
                        duration={500}
                        to="footer-section"
                        style={{ cursor: "pointer", textDecoration: "none" }}
                      >
                        Email
                      </ScrollLink>
                    </MenuItem>
                    <MenuItem
                      value="Telegram"
                      className={classes.menuItem}
                      onClick={() => redirectSupportToTelegram()}
                    >
                      Telegram Chat
                    </MenuItem>
                  </Select>

                  <ScrollLink
                    onClick={() => history.push("/")}
                    smooth={true}
                    duration={500}
                    to="hypertrade"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <ListItem className={classes.linkButton1}>
                      About us
                    </ListItem>
                  </ScrollLink>

                  <Select
                    value={selectedS}
                    onChange={handleChangeS}
                    displayEmpty
                    className={classes.spotSmartT}
                    MenuProps={{ classes: { paper: classes.menu1 } }}
                  >
                    <MenuItem value="" disabled className={classes.menuItem}>
                      Social Media
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        redirectToTwitter();
                        handleClose2();
                      }}
                      value="Twitter"
                      className={classes.menuItem}
                    >
                      Twitter
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        redirectToYoutube();
                        handleClose2();
                      }}
                      value="Youtube"
                      className={classes.menuItem}
                    >
                      Youtube
                    </MenuItem>
                    <MenuItem
                      value="Medium"
                      onClick={() => {
                        redirectToMedium();
                        handleClose2();
                      }}
                      className={classes.menuItem}
                    >
                      Medium
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        redirectToTelegram();
                        handleClose2();
                      }}
                      value="Telegram"
                      className={classes.menuItem}
                    >
                      Telegram
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        redirectToInstagram();
                        handleClose2();
                      }}
                      value="Instagram"
                      className={classes.menuItem}
                    >
                      Instagram
                    </MenuItem>
                  </Select>
                  {logIn == true && (
                    <ListItem
                      className={classes.register}
                      onClick={() => history.push("/dashboard")}
                    >
                      Dashboard
                    </ListItem>
                  )}
                  {logIn == false && (
                    <ListItem
                      className={classes.register}
                      onClick={() => history.push(signupUrl)}
                    >
                      Register
                    </ListItem>
                  )}
                  {logIn == false && (
                    <ListItem
                      className={classes.Login}
                      onClick={() => history.push("/login")}
                    >
                      Login
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          </Drawer>
        </AppBar>
      </div>
    </>
  );
}
