// const url = "https://nodepune-royalq.mobiloitte.io/api/v1";
// const predictionUrl = "https://nodepune-predictionroyalq.mobiloitte.io/api/v1";

const url = "https://nodepune-royalq.mobiloitte.io/api/v1";
const predictionUrl = "https://nodepune-predictionroyalq.mobiloitte.io/api/v1";
const strategyUrl = "https://nodepune-martingaleroyalq.mobiloitte.io/api/v1";

// const url = "http://172.16.6.52:3038/api/v1";
// https://node-prediction.olympustrade.ai/
const ApiConfig = {
  login: `${url}/user/user-login`,
  userSignUp: `${url}/signup`,
  userSendOtp: `${url}/user/user-Register-Send-Otp-Email`,
  userVerify: `${url}/user/user-Signup-verify-Otp-Email`,
  otpVerify: `${url}/user/user-Forgot-verify-Otp-Email`,
  verifyOtpEmail: `${url}/user/userRegisterVerifyOtpEmail`,
  reset: `${url}/user/user-Forgot-Password`,
  forgotPassword: `${url}/user/user-Forgot-Password-Send-OTP`,
  userRegisterSendOTP: `${url}/user/user-register-Send-OTP`,
  userRegister: `${url}/user/userRegister`,
  viewMyProfile: `${url}/user/viewMyProfile`,
  editUserProfilePic: `${url}/user/user-editUserProfilePic`,
  getAllBanners: `${url}/banner/get-all-banners-of-web`,
  getTradingBotDetails: `${url}/buy/getTradingBotDetails`,
  shareMyReferralLinks: `${url}/user/shareMyReferralLinks`,
  numbersofCommunityMember: `${url}/user/communityMemberdashboard`,
  directReferral: `${url}/user/directReferralDashboard`,
  getActivationGainCount: `${url}/user/getActivationGainCount`,
  getAdvertismentForUser: `${url}/admin/getAdvertismentForUser`,
  buyPlanData: `${url}/buy/buyPlanData`,
  listExchange: `${url}/exchangeWallet/listExchange`,
  serverIPAddress: `${url}/exchangeWallet/serverIPAddress`,
  connectExchange: `${url}/exchangeWallet/connectExchange`,
  getVipMemberInterest: `${url}/buy/getVipMemberInterest`,
  exchangeWallet: `${url}/exchangeWallet/exchangeBalance`,
  referralSummary: `${url}/user/getDirectReffralSummary`,
  referralDetails: `${url}/user/getDirectReffralTeamDashboard`,
  getTeamAccountSettings: `${url}/user/getTeamAccountSettings`,
  getTeamAccountDirectRefferal: `${url}/user/getTeamAccountDirectRefferal`,
  assetDetails: `${url}/assets/assetDetails`,
  deposit: `${url}/assets/deposit`,
  WithdrawAsset: `${url}/assetsTransaction/WithdrawAsset`,
  verifyTransaction: `${url}/assetsTransaction/verifyTransaction`,
  confirmAssetTransaction: `${url}/assetsTransaction/confirmAssetTransaction`,
  transferAsset: `${url}/assetsTransaction/transferAsset`,
  userdashboard: `${url}/user/userdashboard`,
  checkReceiverAccount: `${url}/assetsTransaction/checkReceiverAccount`,
  postTeamAccountDirectRefferal: `${url}/user/postTeamAccountDirectRefferal`,
  getTickers: `${url}/exchangeWallet/getTickers`,
  planDetails: `${url}/buy/getPlanDetails`,
  directReffrelCountBar: `${url}/user/directReffrelCountBar`,
  sendVerificationCode: `${url}/exchangeWallet/sendVerificationCode`,
  getRevenueDetails: `${url}/user/getRevenueDetails`,
  customer: `${url}/contactUs/keep-in-touch`,
  subscriber: `${url}/subscriber/user-subscribe`,
  listNotification: `${url}/notification/listNotification`,
  totalUsersCount: `${url}/static/totalUsers`,
  transactionHistoryData: `${url}/assetsTransaction/transactionHistoryForUserWithdrawDepositTransfer`,
  directAndIndirectGain: `${url}/assetsTransaction/transactionHistoryForUserActivationDirectGainANDActivationIndirectGain`,
  autoRenew: `${url}/user/autoRenew`,
  getPopularAssets: `${url}/static/getPopularAssets`,
  directReferralDashboard: `${url}/user/directReferralDashboard`,
  getRecentTransactions: `${url}/user/getRecentTransactions`,
  strategyProfit: `${url}/user/strategyProfit`,
  removeConnectedExchange: `${url}/exchangeWallet/removeConnectedExchange`,
  modifyPassword: `${url}/user/modifyPassword`,
  transactionPassword: `${url}/user/user-Forgot-Transaction-Password`,
  updateTransactionPassword: `${url}/user/forgotTransactionPasswordUpdate`,
  announcementContentList: `${url}/announcement/announcementContentList`,
  viewannouncementContent: `${url}/announcement/viewannouncementContent`,
  addRemoveFavorites: `${url}/exchangeWallet/addRemoveFavorites`,
  conectexchangeList: `${url}/exchangeWallet/connectedExchangeList`,
  getSingleStaticContent: `${url}/static-content/getSingleStaticContentByType`,
  addFeedback: `${url}/contactUs/addFeedback`,
  feedbackListforWeb: `${url}/contactUs/feedbackListforWeb`,
  viewFeedback: `${url}/contactUs/viewFeedback`,
 
  //CMS Article
  listArticleByCategoryIdForUser: `${url}/admin/listArticleByCategoryIdForUser`,
  listCategoryForUser: `${url}/admin/listCategoryForUser`,
  viewArticleForUser: `${url}/admin/viewArticleForUser`,

  //Martingale
  getBotData: `${strategyUrl}/martingale/strategyBotList`,
  getTradeConfiguration: `${strategyUrl}/martingale/getTradeConfiguration`,
  marginConfiguration: `${strategyUrl}/martingale/getMarginConfiguration`,
  DistributedAndTakeProfitAllocation: `${strategyUrl}/martingale/getDistributedAndTakeProfitAllocation`,
  setMartingaleConfiguration: `${strategyUrl}/martingale/setMartingaleConfiguration`,
  startStopMartingale: `${strategyUrl}/martingale/startStopMartingale`,
  martingaleRunningList: `${strategyUrl}/martingale/getMartingaleRunningList`,
  ordersTransactions: `${strategyUrl}/martingale/getOrdersTransactions`,
  ordersLog: `${strategyUrl}/martingale/getMartingaleLogs`,
  closeMartingale: `${strategyUrl}/martingale/closeMartingale`,
  

  //Prediction
  participate: `${predictionUrl}/pool/participate`,
  leaderboard: `${predictionUrl}/pool/get-leader-board`,
  grtPredictionHistory: `${predictionUrl}/pool/get-my-prediction-history`,
  grtPredictionData: `${predictionUrl}/pool/get-my-prediction-revenu`,

  //AIStatergy
  getCoinList: `${url}/indicator/getCoinList`,
  exchangeList: `${url}/indicator/exchangeList`,
  startAiSync: `${url}/indicator/startAiSync`,
  stopAiSync: `${url}/indicator/stopAiSync`,
  getAiSyncInfo: `${url}/indicator/getAiSyncInfo`,
  getAiStrategyHistory: `${url}/indicator/getAiSyncHistory`,
};
export default ApiConfig;
