import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  IconButton,
  Dialog,
  Tooltip,
  CircularProgress,
  Typography,
  Paper,
  Button,
} from "@material-ui/core";
import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";
import DialogContent from "@material-ui/core/DialogContent";
// import NotificationList from "src/views/pages/notifications/index";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import Badge from "@material-ui/core/Badge";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { AuthContext } from "src/context/Auth";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import { UserContext } from "src/context/User";
import LanguageIcon from "@material-ui/icons/Language";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SecurityIcon from "@material-ui/icons/Security";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { toast } from "react-toastify";
import { TfiAnnouncement } from "react-icons/tfi";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#2A2A2B",
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    borderRadius: "25px",
    // background:
    //   "linear-gradient(144deg, #00ACEB 0%, #00B0ED -0.82%, #1069C2 70.35%, #1069C2 100%)",
    height: 266,
    background: "#004AAD",
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },
  search: {
    height: "50px",
    width: "100%",
    maxWidth: "376px",
    color: "#ABABAB",
    borderRadius: "100px",
    display: "flex",
    backgroundColor: "#302F35",
    alignItems: "center",
    justifyContent: "left",
    margin: "0px 5px",
    marginTop: "5px",
    marginLeft: "8px",
    "& input": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchInput: {
    color: "#ABABAB",
    cursor: "text",
    display: "inline-flex",
    position: "relative",
    fontSize: "14px",
    boxSizing: "border-box",
    alignItems: "center",
    fontWeight: 400,
    lineHeight: "1.1976em",
    width: "71%",
  },
  searchIcon: {
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#fff",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  NotificationListStyle: {
    background: theme.palette.background.Notification,
    color: theme.palette.text.NotificationColor,
  },
  navbarIcon: {
    display: "flex",
  },
  linkcolor: {
    color: "#fff",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16.6667px",
    lineHeight: "29px",
  },
  iconColor: {
    "@media(max-width: 767px)": {
      display: "none",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
  mediaLogo: {
    paddingTop: "0px",
    cursor: "pointer",
    width: "112px",
    paddingLeft: "35px",
    "@media only screen and (max-width: 450px)": {
      paddingLeft: "0px !important  ",
    },
  },
  listMenu: {
    display: "grid",
    gap: "10px",
    // padding: "0 34px",
    [theme.breakpoints.down("md")]: {
      // padding: "0, 20px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "0 16px",
    },
  },
  menuListText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "25px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  menuListSubText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "12px",
    lineHeight: "25px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  menuListDateText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "12px",
    lineHeight: "25px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    opacity: "0.7",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  notificationTitle: {
    color: "#2FF3FF",
    display: "flex",
    fontSize: "28px",
    fontStyle: "normal",
    ontFamily: "Roboto",
    alignItems: "center",
    fontWeight: "600",
    lineHeight: "38px",
    textTransform: "capitalize",
  },
  ViewAllBtnDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  viewAllBtn: {
    width: " 20%",
    height: "20%",
    padding: " 5px 10px",
    color: "#FFFFFF",
    background: "linear-gradient(180.99deg, #2FF3FF -25%, #1E92AA 141.48%)",
  },
  announcementText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "normal",
    color: "#fff",
    "@media(max-width:500px)": {
      display: "none",
    },
  },
  dividerTopBar: {
    borderRight: "1.8px solid #636363",
    height: "34.095px",
    width: "2px",
    margin: "0 5px",
  },
  TfiIcon: {
    color: "rgb(255, 255, 255)",
    display: "none",
    "@media(max-width:500px)": {
      display: "block",
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [themeChange, seThemeChange] = useState("DARK");
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const [unReadNotification, setUnReadNotification] = useState(0);

  const [open, setOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  const [count, setCount] = useState([]);
  const [loader, setLoader] = useState(false);

  const [notiData, setNotiData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  const notificationHandler = async () => {
    setLoader(true);
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.listNotification,
        params: { limit: 5 },
        headers: {
          token: token,
        },
      });

      if (res.data.statusCode === 200) {
     
        setNotiData(res.data.dataResults.docs);
        console.log("notification data" , res.data.dataResults.docs);
        setLoader(false);
      } else {
        toast.warn(res.data.responseMessage);
        setLoader(false);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.message);
      setLoader(false);
    }
  };

  return (
    <>
      <Logo className={classes.mediaLogo} />
      <Box flexGrow={1} />
      &nbsp;&nbsp;
      {/* <IconButton
        onClick={() => {
          history.push("/disclaimer");
        }}
      >
        <Typography className={classes.announcementText}>
          Announcement
        </Typography>
        <TfiAnnouncement className={classes.TfiIcon} />
      </IconButton>  */}
      {/* <div className={classes.dividerTopBar} >
      
      </div> */}
      <IconButton
        style={{
          color: "#fff",
        }}
      >
        <PhoneIphoneIcon />
      </IconButton>
      <IconButton
        style={{
          color: "#fff",
        }}
      >
        <SecurityIcon />
      </IconButton>
      <IconButton
        style={{
          color: "#fff",
        }}
        onClick={() => {
          setOpen(true);
          notificationHandler();
        }}
      >
        <NotificationsIcon />
      </IconButton>
      <SwipeableTemporaryDrawer />
      <Dialog
        PaperComponent={Paper}
        PaperProps={{
          style: {
            position: "fixed",
            top: 20,
            right: 0,
            padding: "20px",
            width: "100%",
            maxWidth: "438px",
            background: "#2A2A2A",
            borderRadius: "10px",
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
       >


        {notiData.length != 0 ? (
          <>
            <div className={classes.ViewAllBtnDiv}>
              <Typography className={classes.notificationTitle}>
                Notifications
              </Typography>

              {notiData.length == 0 ? (
                ""
              ) : (
                <Button
                  className={classes.viewAllBtn}
                  onClick={() => {
                    history.push("/notifications");
                    setOpen(false);
                  }}
                >
                  View All
                </Button>
              )}
            </div>

            <Box mt={4} className={classes.listMenu}>

              {notiData &&
                notiData.map((value) => {
                  return (
                    <Box
                      style={{
                        background: "#3E3E3E",
                        padding: "12px",
                        borderRadius: "10px",
                      }}
                    >
                      {loader ? (
                        <CircularProgress size="20px" sx={{ color: "white" }} />
                      ) : (
                        ""
                      )}
                      {" "}
                      <div className={classes.ViewAllBtnDiv}>
                        <Box>
                          <Typography className={classes.menuListText}>
                            {value.title}
                          </Typography>
                          <Typography className={classes.menuListSubText}>
                            {value.body}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.menuListDateText}>
                            {value.createdAt}
                          </Typography>
                        </Box>
                      </div>
                    </Box>
                  );
                })}
            </Box>
          </>
        ) : (
          <>
            <Typography className={classes.notificationTitle}>
              Notifications
            </Typography>
          
            <Box mt={4} className={classes.listMenu}>
              <Typography
                style={{
                  color: "white",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  textAlign: "center",
                }}
              >
                No Records
              </Typography>
            </Box>
          </>
        )}

        
      </Dialog>
    </>
  );
}
