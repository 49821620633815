import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";


export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/home/home")),
  },
  {
    exact: true,
    path: "/register/:inviteCode?",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup.js")),
  },
  {
    exact: true,
    path: "/signup-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/OtpVerification")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/verify-otp",

    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/forget-password-link/OtpVerify")
    ),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  // {
  //   exact: true,
  //   path: "/changePassword",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Change Password/Index")),
  // },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/index.js")),
  },
  {
    exact: true,
    path: "/pricing",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Pricing/index")),
  },
  {
    exact: true,
    path: "/referral",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Referral/index")),
  },
  {
    exact: true,
    path: "/asset",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetsFlow/Assets")),
  },
  {
    exact: true,
    path: "/pricing",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Pricing/index")),
  },
  {
    exact: true,
    path: "/olympus-support",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OlympusSupport/index")),
  },
  {
    exact: true,
    path: "/olympus-support-raised-issue-history",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OlympusSupport/IssueHistory")),
  },
  {
    exact: true,
    path: "/marketplace",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Marketplace/index.js")),
  },
  {
    exact: true,
    path: "/prediction",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/prediction/Prediction")),
  },
  {
    exact: true,
    path: "/leaderboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/prediction/Leaderboard")),
  },
  {
    exact: true,
    path: "/prediction-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/prediction/PredictionHistory")),
  },
  {
    exact: true,
    path: "/api-binding",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ApiBinding/index")),
  },
  {
    exact: true,
    path: "/security-center",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/SecurityCenter/index")),
  },
  {
    exact: true,
    path: "/strategy",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/index")),
  },
  {
    exact: true,
    path: "/system-setting",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/SystemSetting/index")),
  },
  {
    exact: true,
    path: "/notifications",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Notifications/index")),
  },
  {
    exact: true,
    path: "/copier",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Copier/index")),
  },
  // {
  //   exact: true,
  //   path: "/copierprofit",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Copier/copierProfit")),
  // },

  {
    exact: true,
    path: "/create",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/Create")),
  },
  {
    exact: true,
    path: "/create-your-own",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/createYourOwn")),
  },
  {
    exact: true,
    path: "/trading-features",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/tradingFeatures")),
  },
  {
    exact: true,
    path: "/select-coin",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/coinSelection")),
  },
  {
    exact: true,
    path: "/sync-robot-strategy",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/SyncRobotStrategy")),
  },
  {
    exact: true,
    path: "/sync-ai-strategy",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/SyncAIstrategy/SyncAiStrategy")),
  },
  {
    exact: true,
    path: "/sync-ai-strategy-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/SyncAIstrategy/SyncAiStrategyHistory")),
  },
  {
    exact: true,
    path: "/sync-robot-sync",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Strategy/SyncRobotSync")),
  },
  {
    exact: true,
    path: "/account-setting",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/AccountSetting/accountSetting")
    ),
  },
  {
    exact: true,
    path: "/team",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AccountSetting/team")),
  },
  {
    exact: true,
    path: "/terms&condition",
    guard: false,
    // layout: LoginLayout,
    component: lazy(() => import("src/views/pages/license/termsCondition")),
  },
  {
    exact: true,
    path: "/risk-notice",
    guard: false,
    // layout: LoginLayout,
    component: lazy(() => import("src/views/pages/license/riskNotice")),
  },
  {
    exact: true,
    path: "/user-service",
    guard: false,
    // layout: LoginLayout,
    component: lazy(() => import("src/views/pages/license/userService")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    guard: false,
    // layout: LoginLayout,
    component: lazy(() => import("src/views/pages/license/privacyPolicy")),
  },
  {
    exact: true,
    path: "/anouncements",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/anouncement/anouncementList")),
  },
  {
    exact: true,
    path: "/disclaimer",
    component: lazy(() => import("src/views/pages/anouncement/CsmAnouncement")),
    // layout: LoginLayout,
  },
  {
    exact: true,
    path: "/anouncement-detail",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/anouncement/viewAnouncement")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
